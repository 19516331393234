import * as React from "react"
import { makeStyles } from "@material-ui/core"

export const WhiteButton = ({ text, onClick, height }) => {
  const classes = useStyle({ height })

  return (
    <button className={classes.button} onClick={onClick}>
      {text}
    </button>
  )
}

const useStyle = makeStyles(theme => ({
  button: {
    backgroundColor: theme.palette.white,
    borderRadius: 35,
    border: "1px solid",
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    height: ({ height }) => height || 56,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "140%",
    cursor: "pointer",
    padding: "0 24px",
    margin: "auto",
    fontFamily: "GT Welsheim Pro, Arial, sans-serif",
  },
}))

export default WhiteButton
