import HelpTextCard from "../HelpTextCard";
import * as React from "react"
import { Link, styled, Typography } from "@material-ui/core"

import QualireparLogo from "./assets/qualirepar-logo.svg"

const CardContent = styled("span")(({ theme }) => ({
  fontSize: "14px",
}))

const CardTitle = styled(Typography)(({theme}) => ({
    color: theme.palette.text.turquoise,
    textTransform: "uppercase",
    fontWeight: "bold",
}))

const TurquoiseLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.turquoise,
}))
const Title = () => <CardTitle>Réparateur labellisé </CardTitle>

const getBonusMaximumAmount = () => {
    return 50
}

const ReparationBonus = () => {
  return (
    <HelpTextCard
      shouldHaveBorder={false}
      oneLineCentered={true}
      mb={4}
      py={1}
      cardTitle={<Title />}
      image={<QualireparLogo alt="logo qualirépar" />}
    >
      <CardContent>
        <span>
          Bénéficiez d’une aide de -20€ à -{getBonusMaximumAmount()}€ sur votre réparation.
          <TurquoiseLink
            underline="always"
            href="https://murfy.fr/landing/bonus-reparation-informations"
            target="_blank"
          >
            + d’infos
          </TurquoiseLink>
        </span>
      </CardContent>
    </HelpTextCard>
  )
}

export default ReparationBonus
