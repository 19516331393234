import * as React from "react"

import { RoundedSelect } from "components/roundedSelect/RoundedSelect"

import { repairStorage } from "../../helpers/repairStorage"
import { MenuItem } from "@material-ui/core"

export function ProductInput({ products }) {
  React.useEffect(() => {
    repairStorage.remove()
  }, [])

  const handleSelect = React.useCallback(
    ({ target: { value } }) => {
      let product = {}
      if (value === "multi_product") {
        product["name"] = "multi_product"
      } else {
        product = products.find(product => product._id === value)
      }

      if (!product) {
        return
      }
      repairStorage.setProduct(product)
    },
    [products]
  )

  return (
    <RoundedSelect
      fullWidth
      height={56}
      placeholder="Quel appareil souhaitez-vous réparer ?"
      onChange={handleSelect}
      displayMultiProduct
    >
      {products &&
        products.map(product => (
          <MenuItem key={product._id} value={product._id}>
            {product.name}
          </MenuItem>
        ))}
    </RoundedSelect>
  )
}
