import * as React from "react"
import { Box, makeStyles, Hidden } from "@material-ui/core"
import AddressInput from "components/addressInput"
import PinkButton from "../../PinkButton/PinkButton"

export const StickyForm = ({ activity, showAddressInput, topOffset }) => {
  const classes = useStyle({ topOffset })

  return (
    <Box className={classes.stickyContainer}>
      <Box className={classes.paddingBox}>
        {showAddressInput && (
          <Hidden xsDown implementation="css">
            <Box pr={1} width={340}>
              <AddressInput height={48} />
            </Box>
          </Hidden>
        )}
        <Hidden xsDown implementation="css">
          <PinkButton
            text={activity.buttonText}
            height={48}
            onClick={() => (window.location.pathname = activity.redirectionUrl)}
          />
        </Hidden>
        <Hidden smUp implementation="css">
          <PinkButton
            text={activity.buttonText}
            height={40}
            onClick={() => (window.location.pathname = activity.redirectionUrl)}
          />
        </Hidden>
      </Box>
    </Box>
  )
}

const useStyle = makeStyles(theme => ({
  stickyContainer: {
    position: "fixed",
    margin: "auto",
    zIndex: 20,
    borderRadius: 0,
    right: 0,
    backgroundColor: theme.palette.primary.main,
    left: 0,
    top: ({ topOffset }) => (topOffset ? theme.spacing(8) : 0),
    height: 88,
    [theme.breakpoints.down("xs")]: {
      height: 56,
      top: "0px !important",
    },
  },
  paddingBox: {
    paddingLeft: 170,
    padding: theme.spacing(2),
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "80%",
      paddingLeft: theme.spacing(2),
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
}))
