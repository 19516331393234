import * as React from "react"
import { Box, Divider, styled } from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import useColor from "../../../hooks/useColor"
import WhiteButton from "../../whiteButton/WhiteButton"

export const MobilePackageCard = ({
  packageName,
  packageTitle,
  packageMobileImage,
  backgroundColor,
  checkItems,
  buttonText,
  buttonUrl,
}) => {
  const bgColor = useColor(backgroundColor)

  const WhiteDivider = styled(Divider)(() => ({
    backgroundColor: "white !important",
  }))

  return (
    <Box
      height="inherit"
      width="100%"
      textAlign="left"
      bgcolor={bgColor}
      color="secondary.dark"
      borderRadius={24}
      px={2}
      py={4}
      display="grid"
    >
      <Box height="max-content">
        <Box width={"100%"} justifyContent="space-between" display="flex">
          <Box
            fontSize={21}
            mb={1}
            fontWeight={500}
            alignSelf="self-end"
            lineHeight="140%"
          >
            {packageName}
          </Box>
          <img height="88px" src={packageMobileImage.publicURL} />
        </Box>
        <WhiteDivider />
        <Box mt={2} fontSize={24} fontWeight={800} lineHeight="120%">
          {packageTitle}
        </Box>
        <Box
          fontSize={16}
          lineHeight="130%"
          display="inline-box"
          alignItems="baseline"
        >
          <b>grâce au bonus</b>
          <span style={{ fontSize: 13 }}>&nbsp;hors pièces détachées</span>
        </Box>
        <Box my={3}>
          {checkItems.map(({ itemText }) => (
            <Box display="flex" mb={1}>
              <Box>
                <CheckIcon fontSize="small" />
              </Box>
              <Box fontSize={14} ml={1}>
                {itemText}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box textAlign="center" alignSelf="flex-end">
        <a href={buttonUrl}>
          <WhiteButton height={48} text={buttonText} />
        </a>
      </Box>
    </Box>
  )
}
