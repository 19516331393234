import React from "react"
import { Box, Hidden } from "@material-ui/core"
import { DesktopPackageCard } from "./components/DesktopPackageCard"
import { MobilePackageCard } from "./components/MobilePackageCard"
import showdown from "showdown"

const converter = new showdown.Converter()

export const HomeRepairPackagesBlock = ({ packages, title, subtitle }) => {
  return (
    <Box px="20px">
      <Hidden smDown implementation="css">
        <Box textAlign="center">
          <Box mb={6}>
            <Box
              fontSize={42}
              fontWeight={700}
              lineHeight={"110%"}
              mb={1}
              color="secondary.main"
            >
              {title}
            </Box>
            <Box
              fontSize={24}
              lineHeight="100%"
              fontWeight={500}
              dangerouslySetInnerHTML={{ __html: converter.makeHtml(subtitle) }}
            ></Box>
          </Box>
          <Box display="flex" margin="auto" width="fit-content">
            {packages.map(item => (
              <DesktopPackageCard {...item} />
            ))}
          </Box>
        </Box>
      </Hidden>
      <Hidden mdUp implementation="css">
        <Box textAlign="center">
          <Box mb={6}>
            <Box
              fontSize={24}
              fontWeight={700}
              mb={1}
              lineHeight="120%"
              color="secondary.main"
            >
              {title}
            </Box>
            <Box
              fontSize={18}
              dangerouslySetInnerHTML={{ __html: converter.makeHtml(subtitle) }}
            ></Box>
          </Box>
          <Box
            display="grid"
            gridRowGap={20}
            margin="auto"
            width="100%"
            maxWidth="480px"
          >
            {packages.map(item => (
              <MobilePackageCard {...item} />
            ))}
          </Box>
        </Box>
      </Hidden>
    </Box>
  )
}

export default HomeRepairPackagesBlock
