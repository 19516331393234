import * as React from "react"
import { RoundedInput } from "components/roundedInput/RoundedInput"
import PlaceIcon from "@material-ui/icons/Place"

import { GoogleAutoComplete } from "components/googleAutoComplete/GoogleAutoComplete"
import { repairStorage } from "../../helpers/repairStorage"

export function AddressInput({ height }) {
  const handleChangeAddress = React.useCallback(address => {
    repairStorage.setAddress(address)
  }, [])

  return (
    <GoogleAutoComplete
      onChange={handleChangeAddress}
      id="google-automplete-mobile"
    >
      <RoundedInput
        fullWidth
        height={height || 56}
        backgroundColor="white"
        placeholder="Où devons-nous intervenir ?"
        InputProps={{
          endAdornment: <PlaceIcon color="disabled" />,
        }}
      />
    </GoogleAutoComplete>
  )
}
