import * as React from "react"
import { Box, Typography, makeStyles, Hidden } from "@material-ui/core"
import AddressInput from "components/addressInput"
import ProductInput from "components/productInput"
import MobileSearchForm from "../../mobileSearchForm"
import { StickyForm } from "./StickyForm"
import PinkButton from "../../PinkButton/PinkButton"
import ReparationBonus from "../../ReparationBonus";

export const ActivityForm = ({
  activity,
  products,
  isActive,
  isPreview,
  displayHeaderBanner,
}) => {
  const classes = useStyle()

  const [isSticky, setIsSticky] = React.useState(false)
  const ref = React.useRef(null)

  const REPAIR_TAB_TITLE = "Réparer"

  const scrollListener = React.useCallback(() => {
    if (window.scrollY > 24) {
      setIsSticky(true)
    } else {
      setIsSticky(false)
    }
  }, [ref])

  React.useEffect(() => {
    if (ref.current) {
      document.addEventListener("scroll", scrollListener)
    }
    return () => {
      document.removeEventListener("scroll", scrollListener)
    }
  }, [ref, scrollListener])

  return (
    <Box hidden={!isActive} ref={ref}>
      {isSticky && (
        <StickyForm
          topOffset={displayHeaderBanner}
          showProductInput={false}
          showAddressInput={activity.showAddressInput}
          activity={activity}
        />
      )}
      <Box className={classes.paddingBox}>
        <Box mb={4}>
          <Typography
            color="secondary"
            variant="h1"
            className={classes.titleTextVariant}
          >
            {activity.formTitle}
          </Typography>
        </Box>
        <Box ref={ref}>
          {activity.tabTitle === REPAIR_TAB_TITLE && <ReparationBonus/>}
          <Hidden xsDown implementation="css">
            {activity.formText && <Box mb={4}>{activity.formText}</Box>}
            {activity.showProductInput && (
              <Box pb={2}>
                <ProductInput products={products} />
              </Box>
            )}
            {activity.showAddressInput && !isPreview && (
              <Box pb={2}>
                <AddressInput />
              </Box>
            )}
            <PinkButton
              text={activity.buttonText}
              height={56}
              onClick={() =>
                (window.location.pathname = activity.redirectionUrl)
              }
            />
          </Hidden>
          <Hidden smUp implementation="css">
            {activity.formText && <Box mb={4}>{activity.formText}</Box>}
            {activity.showAddressInput && activity.showProductInput ? (
              <Box>
                <MobileSearchForm products={products}>
                  <PinkButton text={activity.buttonText} height={56} />
                </MobileSearchForm>
              </Box>
            ) : (
              <PinkButton
                text={activity.buttonText}
                height={56}
                onClick={() =>
                  (window.location.pathname = activity.redirectionUrl)
                }
              />
            )}
          </Hidden>
        </Box>
      </Box>
    </Box>
  )
}

const useStyle = makeStyles(theme => ({
  titleTextVariant: {
    fontWeight: 700,
    [theme.breakpoints.down("md")]: {
      fontSize: 44,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 32,
    },
  },
  paddingBox: {
    padding: "32px 56px 16px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: 0,
    },
  },
}))
