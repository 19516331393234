import {Typography, styled, fade} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import React from "react";

const BackgroundBox = styled(Box)(({shouldHaveBorder, image, theme}) => {
    const border = shouldHaveBorder ? "solid 1px" : "0px";
    return ({
        backgroundColor: fade(theme.palette.green, 0.20),
        padding: theme.spacing(2),
        border: border,
        borderRadius: "12px",
        display: "grid",
        gridTemplateAreas: image ? '"a b" "c b"' : '"a a" "c c"',
        gridTemplateColumns: "6fr 1fr",
        [theme.breakpoints.down("sm")] : {
            padding: `0px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
            gridTemplateAreas: image ? '"a b" "c c"' : '"a a" "b b"',
        }
    });
});

const TitleBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    gridArea: "a",
}));

const ImageBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gridArea: "b",
    [theme.breakpoints.down("sm")] : {
        "&> svg": {
            width: "60px",
        },
    },
}));

const TextBox = styled(Box)(({theme}) => ({
    display: "flex",
    gridArea: "c",
    color: theme.palette.text.turquoise,
    textAlign: "left",
}));

const CenteredText = styled(Typography)(({oneLineCentered}) => ({
    lineHeight: oneLineCentered ? "24px" : "inherit",
}));

const HelpText = ({children, oneLineCentered = true}) => {
    return (
        <TextBox>
            <CenteredText oneLineCentered={oneLineCentered} variant="body2">
                {children}
            </CenteredText>
        </TextBox>
    );
};

const HelpTextCard = ({children, ...props}) => {
    const title = !props.cardTitle ? undefined : (<TitleBox> {props.cardTitle} </TitleBox>);
    const image = !props.image ? undefined : (<ImageBox> {props.image} </ImageBox>);

    return (
        <BackgroundBox {...props}>
            {title}
            {image}
            <HelpText className="text" oneLineCentered={props.oneLineCentered}>{children}</HelpText>
        </BackgroundBox>
    );
};

export default HelpTextCard;
