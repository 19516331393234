import * as React from "react"
import { Box, Divider, styled } from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import useColor from "../../../hooks/useColor"
import WhiteButton from "../../whiteButton/WhiteButton"

export const DesktopPackageCard = ({
  packageName,
  packageTitle,
  packageDesktopImage,
  backgroundColor,
  checkItems,
  buttonText,
  buttonUrl,
}) => {
  const bgColor = useColor(backgroundColor)

  const WhiteDivider = styled(Divider)(() => ({
    backgroundColor: "white !important",
  }))

  const HoverBox = styled(Box)(() => ({
    "&:hover": {
      transform: "translateY(-8px)",
    },

    transition: "transform .2s cubic-bezier(.215,.61,.355,1)",
  }))

  return (
    <HoverBox
      height="inherit"
      width="375px"
      minWidth="375px"
      textAlign="left"
      bgcolor={bgColor}
      color="secondary.dark"
      borderRadius={24}
      p={3}
      mr={2}
      ml={2}
      display="grid"
    >
      <Box height="max-content">
        <Box width={"100%"} textAlign="center" mb={5}>
          <img width="70%" src={packageDesktopImage.publicURL} />
        </Box>
        <Box fontSize={26} mb={1} lineHeight="140%" fontWeight={500}>
          {packageName}
        </Box>
        <WhiteDivider />
        <Box mt={2} fontSize={32} fontWeight={800} lineHeight="120%">
          {packageTitle}
        </Box>
        <Box
          fontSize={21}
          lineHeight="130%"
          display="flex"
          alignItems="baseline"
        >
          <b>grâce au bonus</b>
          <Box fontSize={14}>&nbsp;hors pièces détachées</Box>
        </Box>
        <Box my={3}>
          {checkItems.map(({ itemText }) => (
            <Box display="flex" mb={1}>
              <Box>
                <CheckIcon fontSize="small" />
              </Box>
              <Box ml={1}>{itemText}</Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box textAlign="center" alignSelf="flex-end">
        <a href={buttonUrl}>
          <WhiteButton text={buttonText} />
        </a>
      </Box>
    </HoverBox>
  )
}
