import * as React from "react"
import { makeStyles } from "@material-ui/core"

export const PinkButton = ({ text, onClick, height }) => {
  const classes = useStyle({ height })

  return (
    <button className={classes.button} onClick={onClick}>
      {text}
    </button>
  )
}

const useStyle = makeStyles(theme => ({
  button: {
    backgroundColor: theme.palette.pink,
    borderRadius: 35,
    color: "white",
    width: "100%",
    height: ({ height }) => height || 56,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "140%",
    border: "unset",
    cursor: "pointer",
    padding: "0 16px",
    fontFamily: "GT Welsheim Pro, Arial, sans-serif",
  },
}))

export default PinkButton
